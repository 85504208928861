const URL_BASE = process.env.REACT_APP_URL_BASE!;

export const linkRegex = '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?';
export const linkRegexr = new RegExp(linkRegex, 'i');

export const usernameRegex = '[-._a-zA-Z0-9]{3,36}';

export const config = {
	URL_BASE,
	URL_REST: `${URL_BASE}/api/v1`,
	MAPS_KEY: process.env.REACT_APP_MAPS_KEY!,
	API_TOKEN: 'x-access-token',
	API_COOKIE: '@session_id',
	VIDEO_TYPE_LIMIT: ['mp4','quicktime'],
	IMAGE_TYPE_LIMIT: ['jpg','jpeg','png'],
	VIDEO_SIZE_LIMIT: 2044,
	VIDEO_DURATION_LIMIT: 30,
	SUPPORT_EMAIL: 'info@vicktorylegends.com',
	DESCRIPTION_LIMIT: 230,
	ENDORSEMENT_CONFIG: {
		MIN: 1.5,
		MAX: 99999999,
		LABEL: 'Total price',
		PLACEHOLDER: 'Price amount',
		ERROR_REQUIRED: 'This field is required',
		ERROR_INVALID: 'Please, enter valid amount',
	},
};

export const theme = {
	font: 'Open Sans',
};
